<template>
  <page-layout title="Feed" hideBack logo v-if="this.activeUniversity">
    <div class="max-w-lg mx-auto">
      <div class="flex flex-col justify-start items-start h-16">
        <p class="font-poppins font-bold heading-1 text-2xl p-5">
          <span class="font-poppins heading-1 font-bold  text-2xl"> Hey </span>
          <span class="font-poppins capitalize font-bold text-primary">{{
            firstname
          }}</span>
          <span v-if="user.tutor" class="body font-normal text-base leading-5 text-gray-500">
            • Tutor</span>
          <span v-if="!user.tutor" class="body font-normal text-base leading-5 text-gray-500">
            • Student</span>
        </p>
      </div>
      <hr />
      <hr />
      <div class="flex flex-col justify-center items-center mt-2">
        <div class="flex h-10 w-full justify-start items-center mt-2 relative">
          <div class="pointer-events-none right-2 absolute ">
            <svg class="" width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.44799 1.44799C0.406659 2.48933 0.406659 4.17733 1.44799 5.21866L6.22799 9.99999L1.44799 14.7813C0.406659 15.8227 0.406659 17.5107 1.44799 18.552C1.96799 19.0733 2.65066 19.3333 3.33332 19.3333C4.01599 19.3333 4.69866 19.0733 5.21866 18.552L13.772 9.99999L5.21866 1.44799C4.17866 0.406659 2.48799 0.406659 1.44799 1.44799Z"
                fill="#FE8303" />
            </svg>
          </div>
          <div :key="updateTab" class="
                    flex flex-row
                    bov
                    scrollbar-hide
                    gap-x-2
                    scroll scroll-smooth
                    overflow-x-auto
                    pl-2
                    ...
                  ">
            <div class="w-full max-h-min relative">
              <filter-tag v-if="user.university?.abbrev && activeUniversity" class="uppercase" @click="filterUniversity()"
                :dropdown="true" active>{{ uniAbbrev }}</filter-tag>
              <select v-if="(activeCategory === 0)" class="
                inset-0 absolute opacity-0
              " @change="filterUniversity($event.target.value)">
                <option v-for="(university, index) in this.$store.state.universities" :key="index" :value="university.id">
                  {{ university.name }}
                </option>
              </select>
            </div>

            <div v-if="open" class="
                      border-orange
                      overlay
                      z-10
                      absolute
                      w-20
                      mt-10
                      ml-2
                      shadow
                      bg-white
                      rounded-2xl
                    ">
              <a v-for="(university, i) in this.$store.state.universities" :key="i"
                @click="filterUniversity(university.id)" class="block p-1 border-b border-gray-200">
                <div class="flex flex-row items-center justify-center space-x-1">
                  <div class="font-inter text-xs">{{ university.name }}</div>
                </div>
              </a>
            </div>
            <filter-tag v-for="(category, i) in this.$store.state.categories" :key="i"
              @click="filterCategory(category.id)" class="whitespace-nowrap uppercase"
              :active="activeCategory == category.id">
              {{ category.name }}
            </filter-tag>
          </div>
        </div>
      </div>
      <Loading v-if="loading" />
      <div v-else class="flex flex-col items-center mt-2 bg-gray-100 min-h-screen">
        <div class="w-full p-7" v-if="isCurrentUniversity && posts.length == 0">
          <div class="flex flex-col justify-center items-center w-full h-32 shadow-3xl rounded-3xl space-y-6">
            <p class="font-poppins font-bold text-lg">Be the first to post at your university</p>
            <Button shape="default" @click="postpopup">Post</Button>
          </div>
        </div>
        <div class="w-full p-7" v-else-if="!isCurrentUniversity && posts.length == 0">
          <div class="flex flex-col justify-center items-center w-full h-32 shadow-3xl rounded-3xl space-y-6">
            <p class="font-poppins opacity-75 text-sm">Nothing here yet</p>
          </div>
        </div>
        <div v-else :key="updateUniversity">
          <div class="w-full px-2 bg-gray-100 pt-2"
            v-if="this.$store.state.profile?.university_id == this.activeUniversity">
            <div class="w-full relative max-w-lg rounded-xl bg-white">
              <div class="
                        flex flex-row
                        w-full
                        justify-center
                        items-center
                        px-3
                        py-4
                        mt-3
                        mb-3
                      ">
                <Avatar :user="user" :isCurrentUser="isCurrentUser" class="" :name="false" :username="false"
                  size="small" />
                <div @click="postpopup" class="
                          p-2.5
                          flex-auto
                          text-sm
                          h-11
                          flex
                          items-center
                          text-gray-400
                          bg-white
                          rounded-full
                          border
                        ">
                  <p>Your message...</p>
                </div>
                <div :key="update + this.$store.state.postPopup.update">
                  <post-pop-up @close="addPost" :active="showModal || this.$store.state.postPopup.active" :initial="{
                    text: this.$store.state.postPopup.text,
                    isAnonymous: this.$store.state.postPopup.isAnonymous,
                    tags: this.$store.state.postPopup.tags,
                    persist: this.$store.state.postPopup.active,
                  }" />
                </div>
              </div>
            </div>
          </div>
          <!-- <Loading class="-my-32" v-if="postLoading" /> -->
          <div :key="updatePosts" v-for="(_post, i) in posts" class="p-2 gap-y-5">
            <post :redirect="true" :post="_post" />
          </div>
          <div class="py-8 w-full flex justify-center" v-if="posts.length > 0 && this.nextFeed">
            <Button shape="ghost" @click="getFeedPosts" v-if="!nextLoading">Load more</Button>
            <Button shape="ghost" v-else>Loading...</Button>
          </div>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import Loading from "../../components/Loading.vue";
import pageLayout from "../../components/base/pageLayout.vue";
import Avatar from "../../components/Feed/Avatar.vue";
import Post from "../../components/Feed/Post.vue";
import FilterTag from "../../components/Feed/FilterTag.vue";
import BurgerMenu from "../../components/Feed/BurgerMenu.vue";
import modal from "../../components/modal.vue";
import Button from "../../components/Feed/Button.vue";
import PostPopUp from "../../components/PostPopUp.vue";
import { GetFeed, GetPostsByUniversity, GetPostsByCategory } from "../../helpers/API/Feeds";
export default {
  components: {
    modal,
    Avatar,
    Button,
    PostPopUp,
    FilterTag,
    pageLayout,
    Post,
    Loading,
    BurgerMenu,
  },
  props: {},
  data() {
    return {
      user: null,
      firstname: "",
      lastname: "",
      university: "",
      showModal: false,
      update: 0,
      updateTab: 0,
      activeUniversity: 0,
      activeCategory: 0,
      posts: [],
      nextFeed: '',
      loading: true,
      open: false,
      lastType: "",
      myFeed: true,
      tag: '',
      isCurrentUser: false,
      postLoading: false,
      nextLoading: false,
      updateUniversity: 0,
      updatePosts: 0,
    };
  },
  computed: {
    uniAbbrev() {
      const uni = this.$store.state.universities.filter((uni) => this.activeUniversity.toString() === uni.id.toString())[0]
      return uni?.abbreviation;
    },
    isCurrentUniversity() {
      return this.$store.state.profile?.university_id === this.activeUniversity;
    }
  },
  created() {
    this.setUser();
    this.showModal = false;
    this.getFeedPosts("university");
    this.ifCurrentUser();
  },
  methods: {
    setUser() {
      this.user = this.$store.state.user;
      // splitting name into firstname and lastname
      const name = this.user.name.split(" ");
      this.firstname = name[0];
      name.shift();
      this.lastname = name.join(" ");
      this.lastname = this.lastname.replace(/\s+/g, " ").trim();
      this.university = this.user.university.abbrev;
      this.activeUniversity = this.$store.state.profile?.university_id ?? 1;
    },
    postpopup() {
      if (this.myFeed === true) {
        this.showModal = true;
        this.update++;
      } else {
        alert("Please return to your feed to post");
      }
    },

    async filterUniversity(id = this.activeUniversity) {
      this.postLoading = true;
      this.nextFeed = null;
      this.activeCategory = 0;
      this.activeUniversity = id;
      this.posts = [];

      await this.getFeedPosts("university");
    },

    async filterCategory(id) {
      this.postLoading = true;
      if (id === this.activeCategory) return;

      this.nextFeed = null;
      this.activeCategory = id;
      this.posts = [];

      await this.getFeedPosts("category");
    },

    async getFeed(type, cursor) {
      this.showModal = false;
      if (!this.nextFeed) this.loading = true;
      else this.nextLoading = true;
      let res;
      switch (type) {
        case "university":
          res = await GetPostsByUniversity(this.activeUniversity, cursor);
          this.updateUniversity++;
          break;
        case "category":
          res = await GetPostsByCategory(this.activeUniversity, this.activeCategory, cursor);
          break;
        default:
          res = await GetFeed(cursor);
          break;
      }
      this.loading = false;
      this.nextLoading = false;
      return { data: res.data.data, next: res.data.current_page + 1 };
    },

    async getFeedPosts(type) {
      this.showModal = false;
      if (!type && this.activeCategory) type = "category"
      else if (!type && this.activeUniversity) type = "university"
      let cursor = ""
      if (type !== this.lastType) this.nextFeed = null;
      this.lastType = type;
      if (this.nextFeed) cursor = `?page=${this.nextFeed}`
      const { data, next } = await this.getFeed(type, cursor);
      this.posts = this.posts.concat(data);
      this.nextFeed = next;
      this.loading = false;
      this.postLoading = false;
    },
    ifCurrentUser() {
      this.isCurrentUser = this.user.user_id === this.$store.state.profile.user_id || this.user._id === this.$store.state.user._id
    },
    async addPost() {
      this.showModal = false;
      this.updatePosts++;
      this.filterUniversity();
      this.updatePosts++;

    }
  },
  watch: {
    '$store.state.profile': {
      deep: true,
      immediate: true,
      handler(val) {
        this.activeUniversity = val?.university_id ?? 1;
      }
    },
  }
};
</script>
<style scoped>
.box {
  flex-basis: 1.75;
}
</style>